import post1 from './post1.md';
import post2 from './post2.md';

export const posts = [
  {
    id: 'moving-into-eternity',
    title: 'Moving Into Eternity',
    content: post1,
  },
  {
    id: 'blog-post-2',
    title: 'Blog Post 2',
    content: post2,
  },
  // Add more posts here
];